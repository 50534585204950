import {ChangeEvent, KeyboardEvent, FC, ReactNode, useContext, useEffect, useState} from "react";
import {titlePage} from "../../../utils/function";
import {links} from "../../routes";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {AlertModule, Button, Facebook, Illustration1, Instagram, LogoIcon, LogoWrapIcon, ModalLayout, Telegram, Tiktok, Whatsapp, Youtube} from "../../components";
import { UserAuthContext } from "../../context/AuthContext";
import { SettingComponent } from "./SettingComponent";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getCategories, reset } from "../../../redux/feature/category/category.slce";
import { GoogleLogin } from "@react-oauth/google";
import {jwtDecode} from 'jwt-decode';
import { loginWithGoogle } from "../../../redux/feature/auth/auth.slice";

export const LoginWithGoogle = () => {
  const {isLoginLoading, isLoginSuccess, isLoginError, message} = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const handleLoginSuccess = (credentialResponse: any) => {
    // console.log('Success:', credentialResponse);
    const decoded: any = jwtDecode(credentialResponse.credential);

    const data = {
      name: decoded.name,
      email: decoded.email,
      picture: decoded.picture
    }
      
    dispatch(loginWithGoogle(data))

  };

  const handleLoginFailure = () => {
    console.error('Login Failed');
  };
  useEffect(() => {
    if(isLoginSuccess){
      window.location.reload()
    }
  }, [isLoginSuccess]);


  return (
    <>
      {
        isLoginError ? (
            <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
        ) : null
      }
      {
        isLoginLoading ? (
          <Button btnType={"button"} label={"Connexion en cours..."} btnStyle={"is--primary"} active={false} withIconLeft={true} iconLeftClass={"icon-spin"}/>
        ) : (
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={handleLoginFailure}
          />
        )
      }
                      
    </>
  )
}

interface MainLayoutParams {
  title?: string,
  children?: ReactNode
}

export const MainLayout:FC<MainLayoutParams> = ({title, children}) => {
  titlePage(title)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keyWords = searchParams.get('wordkeys')
  const typeSearch = searchParams.get('type')
  
  
  const { info, token, isLoading } = useContext(UserAuthContext);
  const dispatch = useAppDispatch();
  const categoryState: any = useAppSelector((state) => state.category);

  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [openSubMenu, setOpenSubMenu] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")


  useEffect(() => {
    if(typeSearch === "all"){
      setSearch(keyWords || "")
    }
  }, [typeSearch, keyWords])

  let navigate = useNavigate()


  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // e.preventDefault()
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if(search) navigate(links.search + `?wordkeys=${search}&type=all`)
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    dispatch(getCategories({}))
    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  if(isLoading || categoryState?.isLoading){
    return (
        <div className={"kubini-loader"}>
          <i className="icon-spin"/>
        </div>
    )
  }
  if(categoryState?.isError){
    return (
      <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{categoryState?.message}</p>
            <div className={"kubini-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
      </div>
    )
  }

  return (
    <>
      <header className="kubini-header2">
        <div className="kubini-header2__container">
          <div className="kubini-header2__logo kubini-header2-logo">
            <Link to={links.home}>
              <LogoWrapIcon/>
              <span>Enka-Market</span>
            </Link>
          </div>
          
          <div className="kubini-header2__search kubini-header2-search">
            <i className="icon-search"></i>
            <input 
              type="search" 
              placeholder='Mots clés (appuyer sur Entrée pour effectuer la recherche)' 
              value={search} 
              onChange={handleChange} 
              onKeyDown={handleKeyDown}  />
          </div>
          
          <div className="kubini-header2__navigation kubini-header2-navigation">
            <div className="kubini-header2-navigation__account kubini-header2-navigation-account">
              {
                token ? (
                  <>
                    <NavLink to={links.cart} className="kubini-header2-navigation-account-icono" end>
                      <i className=" icon-shopping-cart"></i>
                    </NavLink>
                    {/* <NavLink to={links.messagerie} className="kubini-header2-navigation-account-icono" end>
                      <i className="icon-chat-two-bubbles-oval"></i>
                      <span className="notice"></span>
                    </NavLink>
                    <NavLink to={links.notif} className="kubini-header2-navigation-account-icono" end>
                      <i className="icon-alarm-bell"></i>
                      <span className="notice"></span>
                    </NavLink> */}
                    <NavLink to={links.account} className="kubini-header2-navigation-account-icono avatar" end>
                      {
                        info?.avatar ? (
                          <img src={info?.avatar?.url} alt="" />
                        ) : (
                          <i className="icon-user-single"></i>
                        )
                      }
                    </NavLink>
                  </>
                ) : (
                  <button type="button" className="kubini-header2-navigation-account-btn" onClick={() => setOpenMenu(true)}>
                    <span>Se connecter | S'inscrire</span>
                    <i className="icon-user-single"></i>
                  </button>
                )
              }
            </div>
          </div>
        </div>
        <nav className="kubini-header2__subnav kubini-header2-subnav">
          <div className="kubini-header2-subnav__main">
              <button type="button" onClick={() => setOpenSubMenu(!openSubMenu)}>
                <i className="icon-dashboard"></i>
                <span>Toutes les catégories</span>
              </button>
          </div>
          <div className="kubini-header2-subnav__conainer">
            <div className="kubini-header2-subnav__item">
              <button type="button" onClick={() => navigate(links.events)}>
                <span>Les évènements</span>
              </button>
            </div>
            <div className="kubini-header2-subnav__item">
              <button type="button" onClick={() => navigate(links.posts)}>
                <span>Les publications</span>
              </button>
            </div>
            {
              categoryState?.isSuccess && categoryState?.categories ? (
                categoryState?.categories?.length > 0 ? (
                  categoryState?.categories?.slice(0, 8).map((item: any, index: number) => (
                    <div className="kubini-header2-subnav__item" key={index}>
                      <button type="button" onClick={() => navigate(links.search + `?wordkeys=${item?.slug}&type=category`)}>
                        <span>{item?.name}</span>
                      </button>
                    </div>
                  ))
                ) : null
              ) : null
            }
          </div>
          <div className={`kubini-header2-subnav__menu kubini-header2-subnav-menu ${openSubMenu ? "is--open" : ""}`}>
            <div className="kubini-header2-subnav-menu__contain" >
              {
                categoryState?.isSuccess && categoryState?.categories ? (
                  categoryState?.categories?.length > 0 ? (
                    categoryState?.categories?.map((item: any, index: number) => (
                      <div className={`kubini-header2-subnav-menu__item`} key={index}>
                        <button type="button" onClick={() => {
                          navigate(links.search + `?wordkeys=${item?.slug}&type=category`)
                        }}>
                          <span>{item?.name}</span>
                        </button>
                      </div>
                    ))
                  ) : (
                    <strong>Aucune categorie</strong>
                  )
                ) : null
              }
            </div>
          </div>
        </nav>
        {
          !token ? (
            <ModalLayout size={"is--md"} id={"auth"} fnc={() => setOpenMenu(false)} withClose={true} isOpen={openMenu}>
                <div className="kubini-modal-auth">
                  <div className="kubini-modal-auth__illus">
                    <Illustration1/>
                  </div>
                  <div className="kubini-modal-auth__container">
                      
                    <div>
                      <h2>Connexion ou Inscription</h2>
                      <p>Bienvenue sur Enka-market</p>
                    </div>
                    <div className="kubini-modal-auth__actions">
                      <Button btnType={"button"} label={"Se connecter"} btnStyle={"is--neutral"} active={true} onClick={() => navigate(links.login)}/>
                      <Button btnType={"button"} label={"Créer un compte"} btnStyle={"is--primary"} active={true} onClick={() => navigate(links.register)}/>
                    </div>
                    <div><span>ou</span></div>
                    <div>
                      <LoginWithGoogle/>
                    </div>
                  </div>
                </div>
            </ModalLayout>
          ) : null
        }
      </header>
      <main role="main" className="kubini-main2">{children}</main>
      <footer className="kubini-footer">
        <div className="kubini-footer__container">
          <div className="kubini-footer__header">
            <div className="kubini-footer__logo">
              <LogoIcon/>
            </div>
            <ul className="kubini-footer__contacts">
            <strong>Contacts</strong>
              <li className="kubini-footer__contact">
                <a href="mailto:enkamarketsarl@gmail.com" target="_blank" rel="noreferrer"><i className="icon-mail-send-envelope"></i> Email: enkamarketsarl@gmail.com</a>
              </li>
              <li className="kubini-footer__contact">
                <a href="https://wa.me/message/HDO6GA3VLAGWH1" target="_blank" rel="noreferrer"><Whatsapp/> <span>Whatsapp: +226 06 21 23 85</span> </a>
              </li>
            </ul>
          </div>
          <ul className="kubini-footer__links">
            <strong>Liens rapides</strong>
            <li className="kubini-footer__link">
              <Link to={links.ajout_article}>Vendre un article</Link>
            </li>
            <li className="kubini-footer__link">
              <Link to={links.ajout_post}>Publier une annonce</Link>
            </li>
            <li className="kubini-footer__link">
              <Link to={links.ajout_event}>Publier un evenement</Link>
            </li>
            <li className="kubini-footer__link">
              <Link to={links.faq}>Foire aux questions</Link>
            </li>
            <li className="kubini-footer__link">
              <Link to={links.terms}>Conditions d'utilisation</Link>
            </li>
          </ul>
          <div className="kubini-footer__socials">
            <strong>Rejoingnez nous sur nos reseau</strong>
            <ul>
              <li className="kubini-footer__social">
                <a href="https://t.me/enkamarketofficiel" target="_blank" rel="noreferrer">
                  <Telegram/>
                </a>
              </li>
              <li className="kubini-footer__social">
                <a href="https://tiktok.com/@enka.market" target="_blank" rel="noreferrer">
                  <Tiktok/>
                </a>
              </li>
              <li className="kubini-footer__social">
                <a href="https://www.instagram.com/enkamarket50?igsh=MWtsZXNvbXl5eTBoaA%3D%3D&utm_source=qrl" target="_blank" rel="noreferrer">
                  <Instagram/>
                </a>
              </li>
              <li className="kubini-footer__social">
                <a href="https://www.facebook.com/share/g/H5LVMcFFkRcshUqv/?mibextid=K35XfP" target="_blank" rel="noreferrer">
                  <Facebook/>
                </a>
              </li>
              <li className="kubini-footer__social">
                <a href="https:////www.youtube.com/@EnkaMarket/sub_confirmation=1" target="_blank" rel="noreferrer">
                  <Youtube/>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="kubini-footer__copyright">
          <p>© 2024 Enka-Market, Inc.</p>
          <span>-</span>
          <p>Designed with <i className="icon-favorite-heart"></i> by <a href="https://hisiastudio.com" target="_blank" rel="noreferrer">Hisia Studio</a></p>
        </div>
      </footer>
      <SettingComponent/>
    </>
  );
};
